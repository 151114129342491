<template>
    <div>
        <page-header pageTitle="USERS.INDEX_TITLE"/>
        <div class="card mb-1" v-if="hastwoAllotments">
            <div class="card-body pt-2 row">
                <div class="col-10 col-xl-3 col-md-3">
                    <label>{{ t('NAV.ALLOTMENT') }}</label>
                    <Select2 :settings="{ width: '100%', placeholder:  t('LOTS.ALLOTMENT')}" :options="allotments"
                             v-model="allotmentId" @select="changeAllotment($event)"/>
                </div>
            </div>
        </div>
        <div class="card" v-if="data?.length">
            <div v-for="(iten, key) in data" :key="iten.id" :class="key != 0 ? 'border-top2': ''" class="p-2">
                <div class="row gutters d-flex justify-content-between">
                    <div class="list-primary hide-text">{{ iten.text }}</div>
                    <div class="color-grey status-active d-flex flex-column" style="padding-right: 1.5rem">
                        <div class="d-flex justify-content-end mb-2" @click.stop.prevent="copyLink(iten.id)">
                            <button class="btn btn-primary">Copiar Link do Cliente</button>
                        </div>
                        <div class="d-flex justify-content-end mb-2" @click.stop.prevent="sendLink(iten.id)">
                            <button class="btn btn-primary">Enviar Link do Cliente</button>
                        </div>
                    </div>
                </div>
                <div class="row gutters space-between">
                    <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-12">
                        <div v-if="iten.phones.length > 0" class="color-grey hide-text  icon-phone-call"
                             style="padding-left: 1.5rem">
                            <div class="color-grey hide-text" style="padding-left: 1rem; display: inline-block"
                                 v-for="(phone) in iten.phones" :key="phone.id">
                                {{ phone.number }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <modal-select-allotment v-if="allotments" :showModal="showAllotmentSelect"
                                    :is-user="true"
                                    @showModal="showAllotmentSelect = $event" @allotmentSelected="allotmentSelected">
            </modal-select-allotment>
        </div>
        <div class="card w-100" v-if="!data?.length">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>Nenhum Usuário foi encontrado</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from '../../components/layouts/pageHeader';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Select2 from 'vue3-select2-component';
import {mapState} from 'vuex';
import modalSelectAllotment from '../allotment/modalSelectAllotmentComponent';
import user from '../../services/user';
import isReator from '../../mixins/isReator';
import Clients from '../../services/Clients';

export default {
    name: "indexUserForImobi",
    components: {
        pageHeader,
        Select2,
        modalSelectAllotment,
    },
    mixins:[isReator],
    data() {
        return {
            data: [],
            showAllotmentSelect: false,
            allotmentId: null,
            modalChoose: null,
        }
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
            allotmentLoad: 'allotmentLoad',
            hastwoAllotments: 'hastwoAllotments',
        }),
    },
    mounted() {
        if (!this.isRealtor()){
            this.$router.push({name: 'Home'});
            return;
        }
        this.allotmentId = localStorage.getItem('userAllotmentId');
        this.showAllotmentSelect = this.hastwoAllotments == true && (localStorage.getItem('userAllotmentId') ? false : true);
        if (this.allotments?.length === 1 || this.allotmentId) {
            this.allotmentId = this.allotmentId ? this.allotmentId : this.allotments[0].id;
            localStorage.setItem('userAllotmentId', this.allotmentId);
            this.index();
        }
    },
    methods: {
        changeAllotment({id}) {
            this.modalChoose = null;
            localStorage.removeItem('userAllotmentId');
            localStorage.setItem('userAllotmentId', id);
            this.index();
        },
        allotmentSelected() {
            this.allotmentId = localStorage.getItem('userAllotmentId');
            this.index();
        },
        sendLink(id){
            let link = window.location.origin + '/cliente-publico/' + localStorage.getItem('companyId') + '/' + id;
            this.$swal.fire({
                title: 'Realmente deseja enviar o link de cadastro de cliente para o corretor',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Clients.sendNotifyUser(id, link).then(() => {
                        this.toast.success('Notificação enviada com sucesso!')
                        this.$store.commit('changeLoading', false);
                    }).catch(() => {
                        this.$store.commit('changeLoading', false);
                    })
                }
            });
        },
        copyLink(id) {
            let link = window.location.origin + '/cliente-publico/' + localStorage.getItem('companyId') + '/' + id;
            const inputTemporario = document.createElement('input');
            inputTemporario.value = link;
            document.body.appendChild(inputTemporario);
            inputTemporario.select();
            document.execCommand('copy');
            document.body.removeChild(inputTemporario);
            this.toast.success('Link copiado com sucesso!');
        },
        index(){
            this.$store.commit('changeLoading', true);
            user.listRealtor(this.allotmentId).then(r => {
                this.data = r.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.data = [];
                this.$store.commit('changeLoading', true);
            })
        }
    }
}
</script>

<style scoped>

</style>